<template>
  <v-alert type="warning" color="info" outlined :value="true">
    Please enable your SMS notification
    <router-link to="/settings/notification">setting</router-link>. TakeIn may
    send you important messages such as when order is ready. Usually SMS
    notification will be send if Chef starts live broadcast.
  </v-alert>
</template>

<script>
export default {
  props: 'show'
}
</script>
