<template>
  <section v-if="rating">
    <v-rating
      v-model="rating.overall_rating"
      background-color="orange lighten-3"
      color="orange"
      :half-increments="true"
      :readonly="true"
      :dense="true"
      size="15"
    ></v-rating>
  </section>
</template>

<script>
export default {
  props: ['rating']
}
</script>
