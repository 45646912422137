<template>
  <v-container fluid v-if="!loading" class="mb-6">
    <v-container v-if="!profile">
      <h3>Not found</h3>
    </v-container>
    <v-container v-else>
      <profile-meta :profile="profile" />
      <v-row>
        <v-card class="elevation-0 transparent" width="100%">
          <v-row v-if="profileAvatar" justify="center">
            <img :src="profileAvatar" class="rounded-circle" />
          </v-row>

          <v-card-title primary-title>
            <v-container>
              <v-row justify="center" align="center">
                <span class="headline mr-3">
                  {{ profile.name }}
                  <span>{{ you ? '(You)' : '' }}</span>
                </span>
                <v-rating
                  v-if="profile.meta && profile.meta.ratings"
                  :value="profile.meta.ratings.overall_rating"
                  background-color="orange lighten-3"
                  color="orange"
                  :readonly="true"
                ></v-rating>
              </v-row>

              <v-row justify="center" v-if="profile.handle">
                <a target="_blank" :href="sharedLink">@{{ profile.handle }}</a>
              </v-row>

              <v-row justify="center">
                <v-col v-for="(permit, key) in profile.permits" md="2" :key="key">
                  <small>Permit: {{ permit.type.text }} #{{ permit.permit }}</small>
                </v-col>
              </v-row>
            </v-container>
            <template v-if="liveStreaming">
              <v-spacer></v-spacer>
              <v-btn text :to="`/watch/` + id + '?stream_id=' + stream_id">
                <v-icon color="red" class="mx-1" title="Live Video Broadcast">fa fa-circle Blink</v-icon>LIVE
              </v-btn>
            </template>
          </v-card-title>

          <v-card-text>
            <h3>{{ profile.title }}</h3>
            <span v-if="profile.isBusinessClose">
              <img width="90" src="/svg/noun_closed_2640959.svg" />
            </span>
          </v-card-text>
          <v-card-text class="word-breaking" v-html="markedin(profile.description)"></v-card-text>
          <v-card-text v-if="profile.hasBusiness">
            <v-row justify="center">
              <v-col cols="2" v-if="profile.businessLogo" sm="2">
                <v-img :src="profile.businessLogo" contain width="70" class="businessLogo" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                Business Name:
                <strong>{{ profile.businessName }}</strong>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" v-if="profile.website">
                Website:
                <a target="_blank" :href="profile.website">{{ profile.website }}</a>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" v-if="profile.businessPhone">
                Business Phone:
                <a :href="'tel:' + profile.businessPhone">{{ profile.businessPhone }}</a>
              </v-col>

              <v-col cols="12" sm="6" v-if="profile.businessEmail">
                Business Email:
                <a
                  :href="'email:' + profile.businessEmail"
                >{{ profile.businessEmail }}</a>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text v-if="this.profile.settings">
            <p v-if="this.profile.settings.canPickup">
              Pickup Address:
              <strong>{{ this.profile['addresses'][0].formatted_address }}</strong>
            </p>

            <p v-if="this.profile.businessHours">
              Business Hours:
              <v-row v-for="(bh, k) in this.profile.businessHours" :key="k">
                <v-col class="mx-4" cols="12">
                  {{ bh.business_days && bh.business_days.join(', ') }}:
                  {{ bh.fromHour }} -
                  {{ bh.endHour }}
                </v-col>
              </v-row>
            </p>
            <p v-if="this.profile.settings.canPickup">
              Pickup Hours:
              <strong>
                {{ this.profile.settings.pickupTimeEarliest }} -
                {{ this.profile.settings.pickupTimeLatest }}
              </strong>
            </p>
            <p v-else>
              Pickup:
              <strong>No</strong>
            </p>

            <p v-if="this.profile.settings.canDeliver">
              Delivery:
              <strong>Yes (Self-Delivery)</strong>
            </p>
            <p v-else>
              Delivery:
              <strong>Yes (Third-Party)</strong>
            </p>
          </v-card-text>
          <v-card-actions>
            <v-row>
              <v-col cols="6" sm="2">
                <h4>{{ $t('Common.profile_social_profiles') }}</h4>
                <a :href="profile.instagram" v-if="profile.instagram" target="_blank">
                  <v-btn icon class="red--text">
                    <v-icon medium>fab fa-instagram</v-icon>
                  </v-btn>
                </a>

                <a :href="profile.twitter" v-if="profile.twitter" target="_blank">
                  <v-btn icon class="light-blue--text">
                    <v-icon medium>fab fa-twitter</v-icon>
                  </v-btn>
                </a>

                <a :href="profile.facebook" v-if="profile.facebook" target="_blank">
                  <v-btn icon class="blue--text text--darken-4">
                    <v-icon medium>fab fa-facebook</v-icon>
                  </v-btn>
                </a>
                <a :href="profile.pinterest" v-if="profile.pinterest" target="_blank">
                  <v-btn icon class="red--text">
                    <v-icon medium>fab fa-pinterest</v-icon>
                  </v-btn>
                </a>
                <a :href="profile.website" v-if="profile.website" target="_blank">
                  <v-btn icon class="cyan--text">
                    <v-icon medium>public</v-icon>
                  </v-btn>
                </a>
                <a :href="profile.yelp" v-if="profile.yelp" target="_blank">
                  <v-btn icon class="red--text">
                    <v-icon medium>fab fa-yelp</v-icon>
                  </v-btn>
                </a>
              </v-col>
              <v-spacer></v-spacer>

              <template v-if="user && !you">
                <v-col cols="6" sm="5" md="4" lg="3">
                  <v-icon
                    v-if="isFavorite"
                    color="green"
                    class="mx-2"
                    @click.prevent="updateFavorite"
                  >favorite</v-icon>
                  <v-icon
                    v-else
                    color="green"
                    class="mx-2"
                    @click.prevent="updateFavorite"
                  >favorite_border</v-icon>

                  <v-btn v-if="!isFollowing" @click.prevent="follow" dark color="light-blue">Follow</v-btn>
                  <v-btn v-else @click.prevent="unfollow" color="none">Unfollow</v-btn>

                  <v-menu open-on-hover button offset-y>
                    <template v-slot:activator="{ on }">
                      <v-btn color="secondary" text id="dishShare" v-on="on">
                        <v-icon>share</v-icon>
                      </v-btn>
                    </template>
                    <v-list class="pa-2">
                      <vue-goodshare-facebook :page_url="sharedLink" has_icon></vue-goodshare-facebook>

                      <vue-goodshare-linkedIn
                        :page_title="shareDesc"
                        :page_image="profile.avatar"
                        :page_url="sharedLink"
                        has_icon
                      ></vue-goodshare-linkedIn>

                      <vue-goodshare-twitter
                        :page_title="shareDesc"
                        :page_url="sharedLink"
                        has_counter
                        has_icon
                      ></vue-goodshare-twitter>
                      <v-icon class="px-4" @click="selectText('selectable')">link</v-icon>
                      <div id="selectable" @click="selectText('selectable')">{{ sharedLink }}</div>
                    </v-list>
                  </v-menu>
                </v-col>
                <v-col cols="6" sm="4" md="2">
                  <v-btn @click="toMessages(profile.id)" color="blue" class="mx-2" dark>
                    <v-icon left>message</v-icon>Send a message
                  </v-btn>
                </v-col>
              </template>
            </v-row>
          </v-card-actions>
          <v-card-text v-if="showUserSettingsAlert">
            <notification-alert />
          </v-card-text>
          <!-- alert that shows applied promo code -->
          <v-card-text v-if="this.$route.query.c">
            <v-alert
              v-if="promo.code"
              type="success"
              color="yellow darken-3"
              outlined
              :value="true"
            >
              <h4>
                Promo Code:
                <u @click="promoDialog = true">
                  <b>{{ promo.code }}</b>
                </u>
                Applied. {{ promo.amount_type + promo.amount }} Off
              </h4>
            </v-alert>
            <v-alert v-else type="error" color="red" outlined :value="true">
              <h4>Invalid Promo Code!</h4>
            </v-alert>
          </v-card-text>
        </v-card>
      </v-row>

      <!-- promo info dialog -->
      <v-dialog v-model="promoDialog" width="800px">
        <v-card class="pa-2">
          <v-card-title>
            <h2 class="grey--text">{{ promo.code }}</h2>
          </v-card-title>
          <v-card-text>
            <p v-if="promo.all_products">Available for Any Dish</p>
            <p v-if="promo.end_date">Available Until {{ promo.end_date }}</p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="promoDialog = false">Ok</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-row>
        <v-col cols="12">
          <v-tabs
            background-color="blue lighten-3"
            slot="extension"
            v-model="tab"
            show-arrows
            icons-and-text
            prev-icon="mdi-arrow-left-bold-box-outline"
            next-icon="mdi-arrow-right-bold-box-outline"
            centered
            align-with-title
          >
            <v-tabs-slider color="yellow"></v-tabs-slider>
            <v-tab>
              Dish List
              <v-icon color="pink darken-3">restaurant</v-icon>
            </v-tab>
            <v-tab>
              Photo Album
              <v-icon color="pink darken-3">photo</v-icon>
            </v-tab>

            <v-tab>
              Reviews
              <v-icon color="pink darken-3 ">thumb_up</v-icon>
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-toolbar color="blue lighten-4" dense v-if="hasMenus">
                <span v-for="(menu, key) in food_menus" :key="key">
                  <v-col class="mx-2">
                    <v-btn text small @click.prevent="jumpToMenu(key)">
                      <div v-if="menu.followerOnly">
                        <v-icon class="red--text">mdi-lock</v-icon>
                        <span class="red--text">{{ menu.name }}</span>
                      </div>
                      <div v-else>{{ menu.name }}</div>
                    </v-btn>
                  </v-col>
                </span>
              </v-toolbar>

              <v-card flat>
                <public-dish-list :userid="id" :isFollowing="isFollowing" :follow="follow"></public-dish-list>
              </v-card>
            </v-tab-item>

            <v-tab-item>
              <v-card flat>
                <public-album></public-album>
              </v-card>
            </v-tab-item>

            <v-tab-item>
              <v-card flat>
                <v-container>
                  <template v-if="chefRating && chefRating.length > 0">
                    <v-row>
                      <v-col
                        class="mb"
                        cols="12"
                        lg="4"
                        xl="3"
                        v-for="(review, index) in chefRating"
                        :key="index"
                      >
                        <v-card min-height="320px">
                          <v-card-title class="d-flex justify-end">
                            <v-icon
                              v-if="me && me.uid && me.uid === review.uid"
                              @click="reviewDetail(review, me)"
                            >far fa-edit</v-icon>
                          </v-card-title>
                          <v-card-text>
                            <v-row class="mx-auto">
                              <v-col cols="12" align-self="center" class="text-center">
                                <v-avatar title class="grey lighten-4">
                                  <img
                                    v-if="review.sender && review.sender.avatar"
                                    :src="review.sender.avatar"
                                    :alt="review.sender.name"
                                  />
                                </v-avatar>
                              </v-col>
                            </v-row>
                            <v-row class="mx-auto">
                              <v-col align-self="center" class="text-center d-flex">
                                <h5
                                  class="mb-0"
                                  v-if="me && me.uid && me.uid === review.uid"
                                >{{ profile.name }}</h5>
                              </v-col>
                            </v-row>
                            <v-row class="mx-auto">
                              <v-col align-self="center" class="text-center">
                                <vue-perfect-scrollbar style="height:80px" :settings="settings">
                                  {{
                                  review.producer_ratings.ratings
                                  .publicComment
                                  }}
                                </vue-perfect-scrollbar>
                              </v-col>
                            </v-row>
                            <v-row class="mx-auto">
                              <v-col cols="5" align-self="center">
                                <h5 class="mb-0">Overall Rating:</h5>
                              </v-col>
                              <v-col cols="7" align-self="center">
                                <v-rating
                                  :value="
                                    review.producer_ratings.ratings.overall
                                  "
                                  :readonly="true"
                                  background-color="orange lighten-3"
                                  color="orange"
                                  medium
                                ></v-rating>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </template>
                  <template v-else>
                    <v-row>
                      <v-col cols="12">
                        <br />Chef doesn't have any ratings yet!
                      </v-col>
                    </v-row>
                  </template>
                </v-container>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <router-link :to="'/complaint/chef/' + id">
            {{
            $t('Common.complaint_report')
            }}
          </router-link>
        </v-col>
      </v-row>
    </v-container>
    <v-btn class="primary" @click="$router.go(-1)" icon>
      <v-icon class="white--text">chevron_left</v-icon>
    </v-btn>
  </v-container>
</template>

<script>
import VueGoodshareFacebook from 'vue-goodshare/src/providers/Facebook.vue'
import VueGoodshareLinkedIn from 'vue-goodshare/src/providers/LinkedIn.vue'
import VueGoodshareReddit from 'vue-goodshare/src/providers/Reddit.vue'
import VueGoodshareTwitter from 'vue-goodshare/src/providers/Twitter.vue'
import { isAvailableNowMenu } from '../../../store/shared/util'

import PublicDishList from './PublicDishList'
import PublicAlbum from './PublicAlbum'
import NotificationAlert from './NotificationAlert'
import ProfileMeta from './ProfileMeta'
import { mapGetters } from 'vuex'
import { globalMixin } from 'fod-core'

import marked from 'marked'

const domain = process.env.VUE_APP_DOMAIN
export default {
  name: 'ChefPublicProfile',
  mixins: [globalMixin],
  components: {
    PublicDishList,
    PublicAlbum,
    ProfileMeta,
    NotificationAlert,
    VueGoodshareFacebook,
    VueGoodshareReddit,
    VueGoodshareLinkedIn,
    VueGoodshareTwitter
  },
  data() {
    return {
      id: null,
      tab: null,
      isFollowing: false,
      shareLink: null,
      showUserSettingsAlert: false,
      isFavorite: false,
      food_menus: [],
      promo: {
        code: null,
        amount: null,
        amount_type: null,
        all_products: null,
        end_date: null
      },
      settings: {
        maxScrollbarLength: 160
      },
      promoDialog: false
    }
  },
  // meta data if there is promo code
  metaInfo() {
    if (!this.loading && this.profile) {
      return {
        meta: [
          { name: 'description', content: this.profile.description },
          { property: 'keywords', content: this.profile.description },

          {
            property: 'og:title',
            content: `${this.profile.name} : ${this.profile.title}`
          },
          { property: 'og:description', content: this.profile.description },
          {
            property: 'og:url',
            content: `${this.sharedLink}`
          },
          { property: 'og:image', content: this.profileAvatar },
          { property: 'og:site_name', content: `${domain}` },
          { property: 'og:type', content: 'article' },
          { property: 'fb:app_id', content: '589382464873646' },

          { name: 'twitter:card', content: 'summary' },
          { name: 'twitter:title', content: this.promo.code },

          {
            name: 'twitter:creator',
            content: `${this.chef ? this.profile.twitter : ''}`
          },
          { name: 'twitter:image:src', content: this.profileAvatar },
          { itemprop: 'name', content: this.profile.name },
          { itemprop: 'description', content: this.profile.description },
          { itemprop: 'image', content: this.profileAvatar }
        ]
      }
    }
  },
  mounted() {
    let id = this.$route.params.id
    if (!id) {
      id = this.$route.query.id
    }

    // gets promo code from url if there is one
    // and gets promo info from fb
    if (this.$route.query.c) {
      let info = {
        code: this.$route.query.c.toUpperCase(),
        chef_id: id
      }
      this.$store.dispatch('getPromotion', info).then(data => {
        if (data.code) {
          this.$store.dispatch('setEmailPromo', data.code)
          console.log('Email code: ', data.code, ' applied.')
          this.promo.code = data.code
          this.promo.amount = data.amount
          this.promo.amount_type = data.amount_type
          this.promo.all_products = data.all_products
          this.promo.end_date = data.end_date
        }
      })
    }

    console.log('Loading profile:', id)
    if (
      this.$route.path.startsWith('/tag') ||
      this.$route.path.startsWith('/producer')
    ) {
      this.$store.dispatch('loadUserProfileByHandle', { tag: id }).then(ch => {
        this.id = ch.id
        if (ch) {
          this.getProfileInfo(ch)
        }
      })
    } else {
      this.id = id
      // this.$store.dispatch('loadUserProfile', { uid: id })
      this.$store.dispatch('loadChefById', id).then(ch => {
        if (ch) {
          console.log(`Profile for ${ch.name} for id:${id} loaded`)
          this.getProfileInfo(ch)
          this.$store.dispatch('loadMenus', id).then(list => {
            this.food_menus = list.filter(menu => this.menuAvailable(menu))
            this.$store.dispatch('setLoading', false)
          })
        }
      })
    }

    // this.$store.dispatch('getUserSettings', 'notification_settings')
  },
  computed: {
    ...mapGetters({
      profile: 'userProfile',
      chef: 'chef',
      me: 'profile',
      user: 'user',
      loading: 'loading',
      activeOrders: 'newOrders',
      userSettings: 'userSettings',
      chefRating: 'chefRating'
    }),
    liveStreaming() {
      return this.profile && this.profile.stream_status === 'live'
    },
    stream_id() {
      return this.profile.stream_id || ''
    },

    sharedLink() {
      if (this.profile.handle) {
        return `https://view.${domain}/producer/` + this.profile.handle
      }
    },
    hasMenus() {
      return this.food_menus && this.food_menus.length > 0
    },
    shareDesc() {
      return 'Check out ' + this.profile.name + ' on @TakeInLocal!'
    }
  },
  methods: {
    markedin(markedString) {
      if (markedString) return marked(markedString, { sanitize: true })
      else return ''
    },
    gotoAddress() {
      console.log('goto')
    },
    getProfileInfo(ch) {
      const id = ch.id
      if (ch && id) {
        this.shareLink = ch.shareLink
        this.$store.dispatch('loadDishList', { force: true, id: id })
        this.$store.dispatch('loadAlbum', id)
        this.$store.dispatch('isFollowing', id).then(flag => {
          console.log('What? is following', flag)
          this.checkFavorite()
          this.isFollowing = flag
          if (this.user) {
            this.checkNotificationAlert()
          }
        })
      } else {
        console.error(`Id or Producer is missing ${id}`, ch)
      }
    },
    checkNotificationAlert() {
      if (!this.userSettings || !this.userSettings.settings.allowSms) {
        this.showUserSettingsAlert = true
      }
    },
    follow() {
      this.isFollowing = true
      this.$store.dispatch('requestFollow', { from: this.user.id, to: this.id })
      this.checkNotificationAlert()
    },

    unfollow() {
      this.isFollowing = false
      this.$store.dispatch('requestUnFollow', {
        from: this.user.id,
        to: this.id
      })
    },
    checkFavorite() {
      this.isFavorite =
        this.me &&
        this.me.favorite_chefs &&
        this.me.favorite_chefs.includes(this.id)
    },
    updateFavorite() {
      this.isFavorite = !this.isFavorite
      this.$store.dispatch('updateFavorite', {
        id: this.id,
        type: 'chef',
        add: this.isFavorite
      })
    },
    jumpToMenu(key) {
      this.$vuetify.goTo('#tab-' + key)
    },
    toMessages(recipient) {
      this.$store
        .dispatch('getThread', { recipient: recipient })
        .then(thread_id => {
          this.$router.push('/messages/' + thread_id)
        })
    },
    reviewDetail(review, consumer) {
      if (consumer && consumer.uid && review.uid === consumer.uid)
        this.$router.push(`/review/order/${review.orderId}`)
    },
    menuAvailable(menu) {
      if (menu.type === 'parent') {
        if (!menu.children || menu.children.length < 1) {
          return false
        }
      } else {
        if (!menu.items || menu.items.length < 1) {
          return false
        }
      }

      if (!isAvailableNowMenu(menu, this.chef)) {
        return false
      }
      return true
    },
    selectText(containerid) {
      if (document.selection) {
        // IE
        let range = document.body.createTextRange()
        range.moveToElementText(document.getElementById(containerid))
        range.select()
      } else if (window.getSelection) {
        let range = document.createRange()
        range.selectNode(document.getElementById(containerid))
        window.getSelection().removeAllRanges()
        window.getSelection().addRange(range)
      }
      document.execCommand('copy')
      this.$store.dispatch('setMessage', {
        title: 'Copied',
        body: 'to clipboard'
      })
    }
  }
}
</script>
<style scoped>
a {
  text-decoration: none;
}
.rounded-circle {
  width: 200px;
  height: 200px;
  border-radius: 50%;
}
.word-breaking {
  word-break: normal !important;
}
</style>
