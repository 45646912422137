<template>
  <v-container fluid v-bind="{ [`grid-list-xs`]: true }">
    <v-layout row wrap>
      <v-flex xs10 sm6 md4 lg3 v-for="image in album" :key="image.id">
        <v-card flat tile class="mx-3">
          <template v-if="image.imageUrl.indexOf('mp4') > 0">
            <video
              height="250px"
              playsinline
              autoplay
              muted
              controls
              ref="video"
            >
              <source :src="image.imageUrl" type="video/mp4" />
            </video>
          </template>
          <template v-else>
            <v-img :src="image.imageUrl" height="250px">
              <v-container
                img
                fill-height
                grid-list-md
                text-center
                image-hover
              >
                <v-layout row wrap align-center>
                  <v-flex>
                    <strong>{{ image.description }}</strong>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-img>
          </template>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  computed: {
    album() {
      return this.$store.getters.album
    }
  }
}
</script>

<style>
img {
  image-orientation: from-image;
}
.image-hover {
  background: white;
  opacity: 0;
}
.image-hover:hover {
  background: white;
  opacity: 0.8;
  color: black;
  cursor: pointer;
}
.image-hover:active {
  background: white;
  opacity: 0;
}
.icon-color {
  color: green;
}
</style>
