<template>
  <span></span>
</template>
<script>
export default {
  props: ['profile'],
  metaInfo() {
    const domain = process.env.VUE_APP_DOMAIN
    return {
      title: this.profile.name,
      meta: [
        { name: 'title', content: this.profile.name },
        {
          name: 'description',
          content: this.profile.title
            ? this.profile.title
            : this.profile.description || ''
        },
        { property: 'og:title', content: this.profile.name },
        {
          property: 'og:description',
          content: this.profile.title
            ? this.profile.title
            : this.profile.description || ''
        },
        {
          property: 'og:url',
          content: `https://view.${domain}/producer/${this.profile.handle}`
        },
        { property: 'og:image', content: this.profileAvatar },
        { property: 'og:image:alt', content: this.profile.name },
        { property: 'og:site_name', content: `${domain}` },
        { property: 'og:type', content: 'profile' },
        { property: 'fb:app_id', content: '589382464873646' },

        { name: 'twitter:card', content: 'summary' },
        { name: 'twitter:title', content: this.profile.name },
        {
          name: 'twitter:description',
          content: this.profile.title
            ? this.profile.title
            : this.profile.description || ''
        },

        { name: 'twitter:creator', content: '@takeinlocal' },
        { name: 'twitter:image:src', content: this.profileAvatar },

        { itemprop: 'name', content: this.profile.name },
        {
          itemprop: 'description',
          content: this.profile.title
            ? this.profile.title
            : this.profile.description || ''
        },
        { itemprop: 'image', content: this.profileAvatar }
      ]
    }
  }
}
</script>
