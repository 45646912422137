<template>
  <v-card v-if="product && product.name" height="450px" :to="'/dish/' + product.id">
    <v-img
      v-if="imageUrl"
      @click.native="showDetail"
      :src="imageUrl"
      height="200px"
      @error="imageLoadError"
    >
      <v-row v-if="isoCode">
        <v-col cols="12" class="px-6">
          <div class="fs-26">
            <flag :iso="isoCode" />
          </div>
        </v-col>
      </v-row>
    </v-img>
    <v-card-text>
      <v-row class="py-0">
        <v-col cols="9" class="py-1">
          <h4 class="title_padding fs-18 mb-0">{{ product.name | capitalize | truncate(33) }}</h4>
        </v-col>
        <v-col cols="3" class="py-1 d-flex align-center">
          <h4 class="fs-14 green--text mb-0" v-if="!deal">{{ product.price | currency }}<small> <view-product-discount :dish="product" showIcon="true" iconOnly="true"/></small></h4>
          <h4 class="fs-14 green--text mb-0" v-else>
            <s>{{ product.price | currency }}</s>
            {{ deal.price | currency }}
            <view-product-discount :dish="product" />
          </h4>
          
        
        </v-col>
       
      </v-row>
      <v-row>
        <v-col cols="12" class="py-1">
          <h5 class="fs-14 px-1">
            By:&nbsp;&nbsp;{{
            product.chef.chef_name | capitalize | truncate(100)
            }}
          </h5>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="mx-1 py-1">
          <vue-perfect-scrollbar
            v-if="product.description"
            style="height:60px;"
            :settings="scroll_settings"
          >
            <div class="fs-14 word-breaking" v-html="markedDown" style="height:100%"></div>
          </vue-perfect-scrollbar>
        </v-col>
      </v-row>
    </v-card-text>
    <v-footer absolute height="auto" color="transparent" class="pa-0">
      <v-card-actions style="width:100%;">
        <v-row>
          <v-col cols="12" class="d-flex align-center justify-space-around pa-2">
            <template v-if="canOrderNow">
              <v-btn text icon :to="'/dish/' + product.id" :disabled="!showOrderBtn">
                <v-icon class="red--text text--darken-2">shopping_cart</v-icon>
              </v-btn>
            </template>
            <template v-else>
              <v-btn text icon :to="'/dish/' + product.id" :disabled="!showOrderBtn">
                <v-icon class="orange--text text--darken-2">add_shopping_cart</v-icon>
              </v-btn>
            </template>
            <span class="fs-14" v-if="deal">Only {{ deal.amount_left }} left</span>

            <v-chip v-if="foodStatus" small dark>{{ foodStatus }}</v-chip>

            <small
              v-if="isReady && (dish.amountLeft > 0 || dish.getAmount == -1)"
              class="ml-1"
            >{{ getAmount(dish.amountLeft) }} left</small>
            <small v-if="isCooking && product.totalServing > 0">{{ product.totalServing }} total</small>
            <router-link :to="'/chef/' + product.uid">
              <v-avatar size="40px" v-if="product.chef && product.chef.avatar">
                <img class="mb-1" :src="product.chef.avatar" alt="product.chef.name" />
              </v-avatar>
            </router-link>
            <v-tooltip
              bottom
              v-if="!deal && product.promotion && product.promotion.length > 0"
              class="mr-1"
            >
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on">
                  <img width="30" src="/svg/coupon.svg" />
                </v-btn>
              </template>
              <span>This product has a promotional discount!</span>
            </v-tooltip>

            <dish-rate v-if="product.ratings" :rating="product.ratings"></dish-rate>
            <!-- coupon indicator -->
          </v-col>
        </v-row>
      </v-card-actions>
    </v-footer>
  </v-card>
</template>

<script>
import DishRate from "./DishRate";
import { FoodMixin } from "../../mixins/foodMixin";
import { mapGetters } from "vuex";
import marked from "marked";
import ViewProductDiscount from './ViewProductDiscount'
export default {  
  props: {
    dish: {
      Type: Object
    },
    deal: {
      Type: Object
    }
  },
  mixins: [FoodMixin],
  components: { DishRate , ViewProductDiscount},
  data() {
    return {
      scroll_settings: {
        maxScrollbarLength: 160
      }
    };
  },
  computed: {
    ...mapGetters({
      cuisineList: "cuisineList"
    }),
    markedDown() {
      return this.product.description
        ? marked(this.product.description, { sanitize: true })
        : "";
    },
    isoCode() {
      if (
        this.product &&
        this.product.origins &&
        this.product.origins.length > 0
      ) {
        let first = this.product.origins[0];
        let select = this.cuisineList.filter(o => {
          return o.name === first;
        })
        if (select && select.length > 0) {
          return select[0].iso;
        }
      }
      return null
    },
    showOrderBtn() {
      return this.product.chef && this.product.chef.status === "approved";
    },
    product() {
      if (this.deal && !this.dish) {
        return this.deal.dish;
      }
      return this.dish;
    },
    canOrderNow() {
      return (
        (this.product.status === "available" ||
          this.product.status === "cooking") &&
        this.product.amountLeft > 0
      );
    }
  },

  methods: {
    showDetail() {
      this.$router.push("/food/" + this.product.id);
    },
    imageLoadError() {
      console.log("Image failed to load. Invalid or broken image url.");
    },
    getAmount(amount) {
      return amount == -1 ? "Unlimited" : amount;
    }
  }
};
</script>

<style scoped>
.title_padding {
  padding-left: 5px;
}
.word-breaking {
  word-break: normal !important;
}
</style>
