<template>
  <v-row v-if="foods">
    <template v-if="menus && menus.length > 0">
      <v-container
        fluid
        v-for="(menu, key) in menus"
        :id="'tab-' + key"
        :key="key"
      >
        <template v-if="productsByMenu(menu).length > 0">
          <v-card-title primary-title>
            <h4 class="mr-2">{{ menu.name }}</h4>
            <v-divider></v-divider>
          </v-card-title>

          <div v-if="!menu.followerOnly">
            <v-row class="mr-4">
              <v-col
                cols="12"
                sm="6"
                md="4"
                lg="3"
                v-for="(dish, k) in productsByMenu(menu)"
                :key="k"
              >
                <food-view :dish="dish"></food-view>
              </v-col>
            </v-row>
          </div>
          <div v-else>
            <template v-if="isFollowing">
              <v-row class="mr-4">
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                  lg="3"
                  v-for="dish in productsByMenu(menu)"
                  :key="dish.id + '_'"
                >
                  <food-view :dish="dish"></food-view>
                </v-col>
              </v-row>
            </template>
            <template v-else>
              <h4 class="mx-8 my-12">
                Secret menu for followers only. Please
                <v-btn @click.prevent="follow" dark color="light-blue"
                  >FOLLOW</v-btn
                >&nbsp;for access.
              </h4>
            </template>
          </div>
        </template>
      </v-container>
    </template>
    <template v-else>
      <v-col
        cols="12"
        sm="4"
        v-for="food in foods"
        :key="food.id + '~'"
        class="mb-2 px-2"
      >
        <food-view :dish="food"></food-view>
      </v-col>
    </template>
  </v-row>

  <v-row v-else>
    <h5>Product list is empty</h5>
  </v-row>
</template>

<script>
// import FoodView from '../../cook/public/FoodView'
import FoodView from 'fod-core/src/components/dish/FoodView'
import { isAvailableNowMenu } from '../../../store/shared/util'
import { mapGetters } from 'vuex'

export default {
  props: ['isFollowing', 'follow', 'userid'],
  components: {
    FoodView
  },
  computed: {
    ...mapGetters({
      chef: 'chef',
      chefMenus: 'menus',
      dishList: 'chefDishList'
    }),
    loading() {
      return this.$store.getters.loading
    },
    foods() {
      return this.$store.getters.chefDishList
    },
    menus() {
      let availableMenus = []
      for (let i = 0; i < this.chefMenus.length; i++) {
        if (isAvailableNowMenu(this.chefMenus[i], this.chef)) {
          availableMenus.push(this.chefMenus[i])
        }
      }
      return availableMenus
    }
  },
  data() {
    return {
      // hideFollowButton: false
    }
  },
  mounted() {
    console.log(this.$store.getters.chefDishList, 'chefDishList')
  },
  methods: {
    // first checks if menu is parent or sub menu
    // if parent, loops through sub menus and gets products
    productsByMenu(menu) {
      let list = []

      if (menu.type && menu.type === 'parent') {
        menu.children.forEach(sub_menu_id => {
          let sub_menu = this.menus.filter(menu => menu.id === sub_menu_id)
          if (sub_menu.length > 0) {
            sub_menu[0].items.forEach(element => {
              let dish = this.foods.filter(d => d.id === element)
              if (dish && dish.length > 0) {
                list.push(dish[0])
              }
            })
          }
        })
        return list
      } else {
        if (menu && menu.items) {
          menu.items.forEach(element => {
            let dish = this.foods.filter(d => d.id === element)
            if (dish && dish.length > 0) {
              list.push(dish[0])
            }
          })
          return list
        }
      }
      return []
    }
  }
}
</script>
